import common from './common-educabot'

export default {
  ...common.button,
  color: `#42B3FF`,
  '@media (max-width: 767px)': {
    color: `white`,
    minWidth: '100%',
    margin: 'auto',
    justifyContent: 'center'
  },
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `white`,
    border: '0.5px solid',
    borderColor: `#FFFFFF`,
    '@media (max-width: 767px)': {
      bg: `#42B3FF`
    },
  }
}