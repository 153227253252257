import common from './common-educabot'

export default {
  ...common.button,
  color: `#FFFFFF`,
  '::before': {
    display: `none`
  },
  '::after': {
    ...common.button['::after'],
    bg: `#FFB800`,
    border: '0.5px solid',
    borderColor: `#FFB800`
  }
}