import React, { useContext } from 'react'
import { graphql } from 'gatsby'

import { Global } from '@emotion/core'
import { ThemeProvider, Flex, Box, css } from 'theme-ui'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import pageContextProvider from '@helpers/pageContextProvider'
import { ModalContextProvider } from '@solid-ui-components/Modal'
import { TabsContextProvider } from '@solid-ui-components/Tabs'

import ModalWithTabs from '../../../../solid-ui/solid-ui-blocks/src/Modal/Block01'
import Header from '../../../../solid-ui/solid-ui-blocks/src/Educabot/Header/header'
import Footer from '../../../../solid-ui/solid-ui-blocks/src/Educabot/Footer/Block01'
import Whastapp from '../../../../solid-ui/solid-ui-components/src/Whastapp/Whastapp'
import ButtonTop from '../../../../solid-ui/solid-ui-components/src/ButtonTop/ButtonTop'

import { useHeader } from '@helpers-blog/useHeader'

import { normalizeBlockContentNodes } from '@blocks-helpers'

export const Layout = ({ children, pageContext, location }) => {

  const { props } = useHeader()
  const allBlockContent = props.children.allBlockContent

  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <ThemeProvider theme={theme}>
      <pageContextProvider.Provider value={{ pageContext, location }}>
        <ModalContextProvider>
          <TabsContextProvider>
            <Flex variant='layout.layout'>
              <Global styles={css(theme => theme.global)} />
              <ModalWithTabs content={content['contact']} />
              <Header content={content['header']} />
              <Box variant='layout.body'>{children}</Box>
              <Footer content={content['footer']} />
              <ButtonTop />
              <Whastapp />
            </Flex>
          </TabsContextProvider>
        </ModalContextProvider>
      </pageContextProvider.Provider>
    </ThemeProvider>
  )
}

