import React from "react"

import { useStaticQuery, graphql } from "gatsby"

export const useHeader = () => {
  const data = useStaticQuery(graphql`
    {
      allBlockContent(filter: {page: {in: ["homepage/educabot"]}}) {
        nodes {
          ...BlockContent
        }
      }
    }
  `)
  const {allBlockContent} = data
  return <pre>{data}</pre>
}


