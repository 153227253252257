import primary from './primary'
import primaryEducabot from './primary-educabot'
import primaryLight from './primary-light'
import primarYellow from './primary-yellow'
import primarYellowOutline from './primary-yellow-outline'
import primaryGreen from './primary-green'
import primaryGreenOutline from './primary-green-outline'
import primaryGradient from './primary-gradient'
import primaryOutline from './primary-outline'
import primaryOutlineEducabot from './primary-outline-educabot'
import secondary from './secondary'
import darkLigth from './dark-light'
import primaryEducabotLight from './primary-educabot-light'
import principalEducabot from './principal-educabot'
import primaryBlack from './primary-black'
import secondaryEducabotOutline from './secondary-educabot-outline'
import secondaryLight from './secondary-light'
import secondaryGradient from './secondary-gradient'
import secondaryOutline from './secondary-outline'
import gradient from './gradient'
import dark from './dark'
import white from './white'
import black from './black'
import disabled from './disabled'
import icon from './icon'

export default {
  primary,
  'primary-light': primaryLight,
  'primary-gradient': primaryGradient,
  'primary-outline': primaryOutline,
  'primary-outline-educabot': primaryOutlineEducabot,
  secondary,
  'secondary-light': secondaryLight,
  'secondary-gradient': secondaryGradient,
  'secondary-outline': secondaryOutline,
  gradient,
  dark,
  white,
  black,
  disabled,
  icon,
  'primary-black': primaryBlack, black,
  'primary-educabot': primaryEducabot,
  'dark-light': darkLigth, black,
  'primary-yellow': primarYellow,
  'primary-yellow-outline': primarYellowOutline,
  'primary-green': primaryGreen,
  'primary-green-outline': primaryGreenOutline,
  'secondary-educabot-outline': secondaryEducabotOutline,
  'primary-educabot-light': primaryEducabotLight,
  'principal-educabot': principalEducabot
}
